import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

export function InputError({ message }) {
  return <span className="c-payment-journey-input-error">{message}</span>;
}

InputError.propTypes = {
  message: PropTypes.string,
};
