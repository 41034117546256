import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PaymentJourneyFormBox from '../../../PaymentJourneyFormBox/PaymentJourneyFormBox';
import PaymentJourneyTable from '../../../PaymentJourneyTable/PaymentJourneyTable';
import CopyBillingAnchor from '../CopyBillingAnchor';
import FormFreeText from '../FormFreeText';
import { Select, TextField } from '../../../formElements';

export function getStateFieldLabel(country) {
  switch (country) {
    case 'US':
      return 'site.purchase.person.state.label';

    case 'CA':
      return 'site.purchase.person.province.label';

    default:
      return 'site.purchase.person.region.label';
  }
}

export function DistanceLearningForm(props) {
  const {
    selectedCountry,
    showCopyBillingAnchor,
    meta: { countries, regions },
  } = props;

  const { t } = useTranslation();

  return (
    <PaymentJourneyFormBox title="Participant Details">
      {showCopyBillingAnchor && (
        <FormFreeText>
          <CopyBillingAnchor>
            {t('site.purchase.delegate-details.copy-billing-information')}
          </CopyBillingAnchor>
        </FormFreeText>
      )}

      <FormFreeText>
        * {t('site.purchase.delegate-details.required-field-information')}
      </FormFreeText>

      <PaymentJourneyTable title="Participant's contact details">
        <table>
          <tbody>
            <tr>
              <td>
                <TextField
                  type="text"
                  name="honorific"
                  label="Title"
                  helperText="e.g. Mr, Mrs, Miss, Ms, Dr, Capt"
                  rules={{ required: true }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <TextField
                  type="text"
                  name="forename"
                  label="First Name"
                  rules={{ required: true }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <TextField
                  type="text"
                  name="surname"
                  label="Last Name"
                  rules={{ required: true }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <TextField
                  type="tel"
                  name="telephone"
                  label="Telephone Number"
                  rules={{ required: true, telephone: true }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <TextField
                  type="tel"
                  name="mobilePhone"
                  label="Mobile Number"
                  rules={{ mobile: true }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <TextField
                  type="email"
                  name="email"
                  label="Work Email"
                  rules={{ required: true, email: true }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <TextField
                  type="email"
                  name="confirmEmail"
                  label="Confirm Email"
                  rules={{
                    required: true,
                    match: {
                      value: 'email',
                      message: t('site.purchase.validation.email.confirm'),
                    },
                  }}
                  onPaste={(event) => event.preventDefault()}
                />
              </td>
            </tr>
            <tr>
              <td>
                <TextField type="text" name="jobTitle" label="Job Title" />
              </td>
            </tr>
            <tr>
              <td>
                <TextField
                  type="text"
                  name="companyName"
                  label="Company Name"
                  rules={{ required: true }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <TextField
                  type="text"
                  name="addressLine1"
                  label="Address Line 1"
                  rules={{ required: true }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <TextField
                  type="text"
                  name="addressLine2"
                  label="Address Line 2"
                />
              </td>
            </tr>
            <tr>
              <td>
                <TextField
                  type="text"
                  name="city"
                  label="City"
                  rules={{ required: true }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <TextField
                  type="text"
                  name="postcode"
                  label="Postcode / Zip code"
                  rules={{ postcode: true }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Select
                  name="country"
                  options={countries}
                  placeholder={t('site.purchase.person.country.placeholder')}
                  label={t('site.purchase.person.country.label')}
                  rules={{ required: true }}
                />
              </td>
            </tr>
            <tr>
              <td>
                {regions[selectedCountry] ? (
                  <Select
                    name="state"
                    options={regions[selectedCountry]}
                    label={t(getStateFieldLabel(selectedCountry))}
                    rules={{ required: true }}
                  />
                ) : (
                  <TextField
                    type="text"
                    name="countryOrState"
                    label={t('site.purchase.person.region.label')}
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>
                <Select
                  name="contactAddress"
                  options={[
                    { value: 'Home Address', label: 'Home Address' },
                    { value: 'Work Address', label: 'Work Address' },
                  ]}
                  label="Contact: This address is my"
                  rules={{ required: true }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </PaymentJourneyTable>
    </PaymentJourneyFormBox>
  );
}

const optionsPropTypes = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

DistanceLearningForm.propTypes = {
  selectedCountry: PropTypes.string,
  meta: PropTypes.shape({
    countries: PropTypes.arrayOf(optionsPropTypes).isRequired,
    regions: PropTypes.shape({
      CA: PropTypes.arrayOf(optionsPropTypes),
      UK: PropTypes.arrayOf(optionsPropTypes),
      US: PropTypes.arrayOf(optionsPropTypes),
    }),
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    showCopyBillingAnchor: state.billingDetails.data.country,
  };
}

export default connect(mapStateToProps)(DistanceLearningForm);
