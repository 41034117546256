import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { TextFieldWrapper, StyledInput } from '../../styles';

export const TextField = (props) => {
  const { type, name, hasError, placeholder, rules } = props;
  const { register } = useFormContext();

  return (
    <TextFieldWrapper type={type} data-testid="email-opt-in-text-field">
      <div>
        <StyledInput
          type={type}
          placeholder={placeholder}
          hasError={hasError}
          {...register(name, rules)}
        />
      </div>
    </TextFieldWrapper>
  );
};

TextField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  placeholder: PropTypes.string,
  rules: PropTypes.object,
};
