import React from 'react';
import PropTypes from 'prop-types';
import { withBrand } from '../../../providers/BrandProvider';
import './PaymentJourneyFormBox.scss';

export function PaymentJourneyFormBox(props) {
  const {
    title,
    text,
    brand: { secondaryColor },
    children,
  } = props;

  return (
    <div className="c-payment-journey-form-box">
      {title && (
        <h1
          className="c-payment-journey-form-box__title"
          style={{ color: secondaryColor }}
        >
          {title}
        </h1>
      )}
      {text && <div className="c-payment-journey-form-box__text">{text}</div>}
      <div className="c-payment-journey-form-box__body">{children}</div>
    </div>
  );
}

PaymentJourneyFormBox.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
  brand: PropTypes.shape({
    secondaryColor: PropTypes.string,
  }),
};

export default withBrand(PaymentJourneyFormBox);
