import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import get from 'lodash/get';
import './PaymentJourneyTable.scss';

export default function PaymentJourneyTable({
  title,
  text,
  fieldsNames = [],
  collapsible,
  children,
}) {
  const {
    formState: { isSubmitting, errors },
  } = useFormContext();

  const canBeCollapsed = collapsible && title;
  const [isCollapsed, setIsCollapsed] = useState(false);

  const hasErrors = fieldsNames.some((name) => {
    return get(errors, name);
  });

  useEffect(() => {
    /**
     * Expand block if inner fields have errors
     */
    if (isCollapsed && hasErrors) {
      setIsCollapsed(false);
    }

    /**
     * Collapse block on submit if there are no errors
     */
    if (!isCollapsed && canBeCollapsed && !hasErrors && isSubmitting) {
      setIsCollapsed(true);
    }
  }, [canBeCollapsed, isCollapsed, hasErrors, isSubmitting]);

  const toggleState = () => {
    if (canBeCollapsed) {
      setIsCollapsed(!isCollapsed);
    }
  };

  const componentClassNames = classNames('c-payment-journey-table', {
    'c-payment-journey-table--collapsible': canBeCollapsed,
    'c-payment-journey-table--collapsed': isCollapsed,
    'c-payment-journey-table--has-errors': hasErrors,
  });

  return (
    <div className={componentClassNames}>
      {title && (
        <h2 className="c-payment-journey-table__title" onClick={toggleState}>
          {title}
          {canBeCollapsed && !hasErrors && (
            <span className="material-icons">
              {isCollapsed ? 'expand_less' : 'expand_more'}
            </span>
          )}
        </h2>
      )}
      {text && <div className="c-payment-journey-table__text">{text}</div>}
      {children && (
        <div className="c-payment-journey-table__body">{children}</div>
      )}
    </div>
  );
}

PaymentJourneyTable.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
  fieldsNames: PropTypes.arrayOf(PropTypes.string),
  collapsible: PropTypes.bool,
};
