import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const TextFieldWrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements.form?.elements?.inputField,
    )}
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements.form?.elements?.textField,
    )}
  ${({ type }) => (type === 'hidden' ? { marginTop: 0 } : null)}
`;
