import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import {
  getFileNameFromPath,
  returnFileSize,
  transformValidationRules,
} from '../../../../helpers';
import { Input } from '../sharedComponents';
import './index.scss';

const fileTypes = {
  '.JPEG': {
    type: 'image/jpeg',
  },
  '.PNG': {
    type: 'image/png',
  },
  '.GIF': {
    type: 'image/gif',
  },
  '.PDF': {
    type: 'application/pdf',
  },
  '.RTF': {
    type: 'text/rtf',
  },
  '.DOCX': {
    type: '.doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  '.XLSX': {
    type: '.xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  '.PPTX': {
    type: '.ppt, .pptx, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  },
};

export function FileField(props) {
  const {
    type,
    name,
    label,
    helperText,
    placeholder,
    limit,
    accept = [],
    rules,
    ...rest
  } = props;

  const { t } = useTranslation();
  const { register, getValues, setValue, trigger, watch } = useFormContext();

  React.useEffect(() => {
    register(name, transformValidationRules(props, { t, getValues }));
  }, [register, name, t, getValues, props]);

  const selectedFiles = watch(name, []) || [];

  const onFileSelect = async (event) => {
    const selectedFile = event.target.files.item(0);

    // Clear File field (important)
    event.target.value = '';

    if (selectedFile) {
      await setValue(name, [...selectedFiles, selectedFile]);
      await trigger(name);
    }
  };

  const onFileRemove = async (index) => {
    await setValue(
      name,
      selectedFiles.filter((f, innerIndex) => index !== innerIndex),
    );
    await trigger(name);
  };

  const acceptedFileTypes = accept
    .map((fileType) => fileTypes[fileType.toUpperCase()].type)
    .join(', ');

  return (
    <Input name={name} label={label} helperText={helperText} rules={rules}>
      {() => (
        <div className="c-payment-journey-file-field">
          <input
            {...rest}
            type="file"
            id={name}
            multiple={false}
            accept={acceptedFileTypes}
            disabled={limit && selectedFiles.length >= limit}
            onChange={onFileSelect}
          />
          <div className="c-payment-journey-file-field__nav">
            <label
              htmlFor={name}
              className="c-payment-journey-file-field__upload-btn"
            >
              <span className="material-icons">publish</span>Upload file
            </label>
            {placeholder && (
              <div className="c-payment-journey-file-field__placeholder">
                {placeholder}
              </div>
            )}
          </div>
          {selectedFiles.length > 0 && (
            <ul className="c-payment-journey-file-field__files">
              {selectedFiles.map(({ path, name, size }, index) => (
                <li key={index}>
                  <span className="material-icons">insert_drive_file</span>
                  {name || getFileNameFromPath(path)}{' '}
                  <small>- {returnFileSize(size)}</small>
                  <span
                    className="material-icons"
                    onClick={() => onFileRemove(index)}
                  >
                    highlight_off
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </Input>
  );
}

FileField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  helperText: PropTypes.node,
  placeholder: PropTypes.node,
  limit: PropTypes.number,
  accept: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(fileTypes))),
  rules: PropTypes.shape({}),
};
