import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const StyledCallToAction = styled.div`
  &&& {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements.form?.elements?.callToAction,
      )}
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles.elements.form?.elements?.callToAction,
      )}
    
    button[type="submit"] {
      min-width: 120px;
    }
  }
`;
