import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { connect } from 'react-redux';
import { Cookies, withCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { COMPACT, DEFAULT, ERROR, SUBSCRIBED } from './utils/constants';
import { SiteBuilderModule } from '../sharedComponents';
import { EmailOptInContent } from './displayStyles/EmailOptInContent';
import { ModuleWrapper } from './styles';

const EmailOptInModule = (props) => {
  const { t } = useTranslation();
  const {
    compactView,
    isAgenda,
    section: {
      contentType,
      title,
      body,
      buttonText,
      confirmationMessage,
      sapProductCode,
    } = {},
    siteHeader: {
      emailUpdatesEnabled,
      agendaEmailUpdatesEnabled,
      sapProductCode: sapProductCodeHead,
    },
    cookies,
  } = props;
  const themeConfig = useContext(ThemeContext);

  const { emailOptInModule } = themeConfig.siteBuilderModules;
  const styleMode = compactView ? COMPACT : DEFAULT;
  const dStyles = emailOptInModule.displayStyles[styleMode];
  const vStyles = emailOptInModule.visualStyles[styleMode];

  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  const sapProductKey = sapProductCode || sapProductCodeHead;

  const cookieName = contentType
    ? `email_opt_in_${contentType}`
    : 'email_opt_in';

  const acceptedSAPCodes = cookies.get(cookieName) || [];
  const moduleAccepted = acceptedSAPCodes.includes(sapProductKey);

  const isModuleEnabled = isAgenda
    ? agendaEmailUpdatesEnabled
    : emailUpdatesEnabled;

  const hideModule =
    moduleAccepted &&
    subscriptionStatus !== SUBSCRIBED &&
    subscriptionStatus !== ERROR;

  if (hideModule || !isModuleEnabled) {
    return null;
  }
  const headingText = title || t('site.email-opt-in.heading');
  const descriptionText = body || t('site.email-opt-in.description');
  const buttonName = buttonText || t('site.email-opt-in.cta.sign-up.label');
  const confirmationMessageText =
    confirmationMessage || t('site.email-opt-in.success.description');

  return (
    <SiteBuilderModule
      section={props.section}
      moduleTheme={{ dStyles, vStyles }}
    >
      <ModuleWrapper
        data-testid="email-opt-in-module-wrapper"
        className="c-email-opt-in-module"
      >
        <EmailOptInContent
          cookieName={cookieName}
          headingText={headingText}
          descriptionText={descriptionText}
          buttonText={buttonName}
          confirmationMessage={confirmationMessageText}
          sapProductCode={sapProductKey}
          setSubscriptionStatus={setSubscriptionStatus}
          subscriptionStatus={subscriptionStatus}
        />
      </ModuleWrapper>
    </SiteBuilderModule>
  );
};

EmailOptInModule.propTypes = {
  compactView: PropTypes.bool,
  section: PropTypes.shape({
    contentType: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string,
    buttonText: PropTypes.string,
    confirmationMessage: PropTypes.string,
    sapProductCode: PropTypes.string,
  }).isRequired,
  isAgenda: PropTypes.bool,
  cookies: PropTypes.instanceOf(Cookies),
  siteHeader: PropTypes.shape({
    sapProductCode: PropTypes.string,
    emailUpdatesEnabled: PropTypes.bool,
  }),
};

const mapStateToProps = (state) => ({
  siteHeader: state.siteHeader.data,
  pageConfig: state.pageConfig,
});

export default connect(mapStateToProps)(withCookies(EmailOptInModule));
