import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const StyledSelect = styled.select`
  &&& {
    -webkit-appearance: none;

    width: 100%;
    height: 45px;
    padding: 10px 12px;
    outline: none;
    margin-top: 10px;

    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements.form?.elements?.inputField?.elements
          ?.input,
      )}

    &:focus {
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.vStyles.elements.form?.elements?.inputField?.elements
            ?.input?.elements?.focus,
        )}
    }

    ${({ theme, hasError }) =>
      hasError
        ? renderStyledElementStyles(
            theme,
            theme.module.vStyles.elements.form?.elements?.inputField?.elements
              ?.inputError,
          )
        : null}

    &:focus {
      ${({ theme, hasError }) =>
        hasError
          ? renderStyledElementStyles(
              theme,
              theme.module.vStyles.elements.form?.elements?.inputField?.elements
                ?.inputError?.elements?.focus,
            )
          : null}
    }
  }
`;
