import React from 'react';
import PropTypes from 'prop-types';
import { CheckGroupInput } from '../sharedComponents';

export function CheckboxGroup(props) {
  return <CheckGroupInput type="checkbox" {...props} />;
}

CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  helperText: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
    }),
  ),
  freeText: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
  }),
  freeTextContent: PropTypes.node,
  layout: PropTypes.oneOf(['ROW', 'COLUMN']),
  rules: PropTypes.shape({}),
};
