import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { transformValidationRules } from '../../../../../helpers';
import { InputHelperText } from '../InputHelperText';
import { InputLabel } from '../InputLabel';
import { InputError } from '../InputError';
import './index.scss';

export function Input(props) {
  const { type, name, label = '', helperText, disabled, children } = props;
  const [focused, setFocused] = useState(false);

  const onFocus = () => {
    setFocused(true);
  };
  const onBlur = () => {
    setFocused(false);
  };

  const { t } = useTranslation();
  const {
    getValues,
    formState: { errors },
  } = useFormContext();

  const rules = transformValidationRules(props, { t, getValues });

  // Fields like `__checkbox__` are only for internal usage
  const errorMessages = Object.keys(get(errors, name) || []).filter(
    (key) => !/^__.+__$/.test(key),
  );

  const componentClassNames = classNames('c-payment-journey-input', {
    [`c-payment-journey-input--${type}`]: type,
    'c-payment-journey-input--error': errorMessages.length > 0,
    'bc-accent-30': focused,
  });

  const isRequired =
    rules?.required || rules?.atLeastOneOption || rules?.atLeastOneFile;

  return (
    <div className={componentClassNames}>
      <div className="c-payment-journey-input__label-wrapper">
        <div className="c-payment-journey-input__label-inner-wrapper">
          {label && (
            <InputLabel htmlFor={name}>
              {label}
              {isRequired ? ' *' : ''}
            </InputLabel>
          )}
          {helperText && <InputHelperText>{helperText}</InputHelperText>}
        </div>
        <ErrorMessage
          name={name}
          render={({ message }) => <InputError message={message} />}
        />
      </div>
      <div className="c-payment-journey-input__input-wrapper">
        {children({ rules: disabled ? undefined : rules, onFocus, onBlur })}
      </div>
    </div>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  helperText: PropTypes.node,
  disabled: PropTypes.bool,
  rules: PropTypes.shape({}),
};
