import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import './index.scss';

export function CheckInput(props) {
  const { id, name, label, rules, ...rest } = props;
  const { register } = useFormContext();

  return (
    <div className="c-payment-journey-check-input">
      <input {...rest} id={id} {...register(name, rules)} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}

CheckInput.propTypes = {
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  value: PropTypes.string, // For Radio button only
};
