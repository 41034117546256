import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const ModuleWrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.siteBuilderModules.emailOptInModule)}

  ${({ theme }) => renderStyledElementStyles(theme, theme.module.vStyles)}
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.dStyles)}
`;
