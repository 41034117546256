import React from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import { Controller, useFormContext } from 'react-hook-form';
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import { toTwoDigitFormat } from '../../../../helpers';
import { Input } from '../sharedComponents';
import './index.scss';

export { MonthSelectPlugin };

export const getFormattedDateByType = (startDate, type) => {
  const YYYY_MM = `${startDate.getFullYear()}-${toTwoDigitFormat(
    startDate.getMonth() + 1,
  )}`;

  if (type === 'DATE_MM') {
    return YYYY_MM;
  }

  return `${YYYY_MM}-${toTwoDigitFormat(startDate.getDate())}`;
};

export function DateField(props) {
  const {
    name,
    type,
    label,
    helperText,
    placeholder,
    options = {},
    rules,
    testId,
    ...rest
  } = props;

  const { control } = useFormContext();

  return (
    <Input name={name} label={label} helperText={helperText} rules={rules}>
      {({ rules }) => (
        <Controller
          {...rest}
          name={name}
          control={control}
          rules={rules}
          defaultValue=""
          render={({ field: { value, onChange } }) => (
            <Flatpickr
              name={name}
              value={value}
              options={{
                altInput: true,
                altFormat: 'F j, Y',
                dateFormat: 'Y-m-d',
                ...options,
              }}
              placeholder={placeholder}
              onChange={([startDate]) => {
                onChange(
                  startDate ? getFormattedDateByType(startDate, type) : '',
                );
              }}
              data-testid={testId}
            />
          )}
        />
      )}
    </Input>
  );
}

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.node,
  helperText: PropTypes.node,
  rules: PropTypes.shape({}),
  testId: PropTypes.string,
};
