import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { Input } from '../Input';
import { CheckInput } from '../CheckInput';
import './index.scss';

const RADIO = 'radio';
const CHECKBOX = 'checkbox';

export function CheckGroupInput(props) {
  const {
    type,
    name,
    label,
    helperText,
    options = [],
    rules,
    freeText: {
      name: freeTextName,
      value: freeTextValue,
      label: freeTextLabel,
    } = {},
    freeTextContent,
    layout = 'ROW',
    children,
  } = props;

  const { register, trigger, watch } = useFormContext();

  const isRadio = type === RADIO;
  const isCheckbox = type === CHECKBOX;
  const freeTextCheckInputValue = watch(isRadio ? name : freeTextName);

  const componentClassNames = classNames({
    'c-payment-journey-check-group-input': true,
    'c-payment-journey-check-group-input--inline': children,
  });

  const layoutClassNames = classNames({
    'col-xs-12': layout === 'ROW',
    'col-xs-12 col-sm-6': layout === 'COLUMN',
  });

  if (children) {
    return (
      <Input name={name} label={label} helperText={helperText} rules={rules}>
        {({ rules }) => (
          <div className={componentClassNames}>{children({ name, rules })}</div>
        )}
      </Input>
    );
  }

  return (
    <Input name={name} label={label} helperText={helperText} rules={rules}>
      {({ rules }) => (
        <div className={componentClassNames}>
          {isCheckbox && <input type="hidden" {...register(name, rules)} />}

          <div className="row">
            {options.map(({ value, label }) => {
              const computedName = `${name}.${value}`;

              return (
                <div key={value} className={layoutClassNames}>
                  {isCheckbox && (
                    <CheckInput
                      id={computedName}
                      type={type}
                      name={computedName}
                      value="true"
                      label={label}
                      onChange={() => {
                        trigger(name).then();
                      }}
                    />
                  )}
                  {isRadio && (
                    <CheckInput
                      id={computedName}
                      type={type}
                      name={name}
                      value={value}
                      label={label}
                      rules={rules}
                    />
                  )}
                </div>
              );
            })}
            {freeTextContent && (
              <>
                {(freeTextName || freeTextValue) && (
                  <div className={layoutClassNames}>
                    <CheckInput
                      id={`${name}.${freeTextValue}`}
                      type={type}
                      name={isRadio ? name : freeTextName}
                      value={isRadio ? freeTextValue : 'true'}
                      label={freeTextLabel}
                      onChange={() => {
                        if (isCheckbox) {
                          trigger(name).then();
                        }
                      }}
                    />
                  </div>
                )}
                {(isCheckbox
                  ? freeTextCheckInputValue === 'true'
                  : freeTextCheckInputValue === freeTextValue) && (
                  <div className="col-xs-12">
                    <div className="c-payment-journey-check-group-input__free-text">
                      {freeTextContent}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </Input>
  );
}

CheckGroupInput.propTypes = {
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  helperText: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  freeText: PropTypes.shape({
    name: PropTypes.string, // For Checkbox only
    value: PropTypes.string, // For Radio button only
    label: PropTypes.node.isRequired,
  }),
  freeTextContent: PropTypes.node,
  layout: PropTypes.oneOf(['ROW', 'COLUMN']),
  rules: PropTypes.shape({}),
};
