import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const StyledLabel = styled.label`
  &&& {
    width: 100%;
    text-align: ${({ theme }) =>
      theme.globalVariables.isRTL ? 'left' : 'right'};
    display: block;
    padding-bottom: 5px;

    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements.form?.elements?.inputField?.elements
          ?.label,
      )}
  }
`;
