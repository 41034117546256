import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

export function InputLabel({ htmlFor, children }) {
  return (
    <label htmlFor={htmlFor} className="c-payment-journey-input-label">
      {children}
    </label>
  );
}

InputLabel.propTypes = {
  htmlFor: PropTypes.string.isRequired,
};
