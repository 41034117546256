import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Input } from '../sharedComponents';
import { Radio } from '../Radio';
import './index.scss';

export function OptionalRadioGroup(props) {
  const {
    name,
    label,
    helperText,
    radio: {
      yes: { value: yesValue = 'true', label: yesLabel },
      no: { value: noValue = 'false', label: noLabel },
    },
    freeTextContent,
    rules,
  } = props;

  const { watch } = useFormContext();
  const radioValue = watch(name);

  return (
    <Input name={name} label={label} helperText={helperText} rules={rules}>
      {({ rules }) => (
        <div className="c-payment-journey-optional-radio-group">
          <div className="row">
            <div className="col-xs-12">
              <Radio
                name={name}
                value={yesValue}
                label={yesLabel}
                rules={rules}
              />
              <Radio
                name={name}
                value={noValue}
                label={noLabel}
                rules={rules}
              />
            </div>
          </div>
          {radioValue === yesValue && (
            <div className="row">
              <div className="col-xs-12">
                <div className="c-payment-journey-optional-radio-group__free-text">
                  {freeTextContent}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Input>
  );
}

OptionalRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  helperText: PropTypes.node,
  radio: PropTypes.shape({
    yes: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.node.isRequired,
    }).isRequired,
    no: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.node.isRequired,
    }).isRequired,
  }).isRequired,
  freeTextContent: PropTypes.node.isRequired,
  rules: PropTypes.shape({}),
};
