import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Input } from '../sharedComponents';

export function Select(props) {
  const {
    name,
    label,
    helperText,
    options,
    blankOption,
    rules,
    children,
    placeholder,
    ...rest
  } = props;

  const { t } = useTranslation();
  const { register } = useFormContext();

  const blankOptionTranslationKey = blankOption
    ? `site.purchase.option.blank.${blankOption.toLowerCase()}`
    : `site.purchase.option.blank.${rules?.required ? 'select' : 'none'}`;

  return (
    <Input name={name} label={label} helperText={helperText} rules={rules}>
      {({ rules, onFocus, onBlur }) => (
        <select
          {...rest}
          id={name}
          onFocus={onFocus}
          onBlur={onBlur}
          {...register(name, rules)}
        >
          {(blankOption !== false || placeholder) && (
            <option value="">
              {placeholder || t(blankOptionTranslationKey)}
            </option>
          )}
          {options?.length
            ? options.map(({ value, label, i18nKey }) => (
                <option key={value} value={value}>
                  {i18nKey ? t(i18nKey) : label}
                </option>
              ))
            : children}
        </select>
      )}
    </Input>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  helperText: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  blankOption: PropTypes.oneOf(['SELECT', 'NONE', false]),
  rules: PropTypes.shape({}),
};
