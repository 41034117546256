import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { Paragraph } from '../../../sharedComponents';
import { Text, StatusIcon, PostMessageWrapper } from '../../styles';
import { useTranslation } from 'react-i18next';

export const EmailOptInPostMessage = (props) => {
  const { t } = useTranslation();
  const { confirmationMessage, isError } = props;

  const themeConfig = useContext(ThemeContext);
  const dStyles = themeConfig.module.dStyles.elements.postMessage;
  const vStyles = themeConfig.module.vStyles.elements.postMessage;

  return (
    <PostMessageWrapper data-testid="email-opt-in-post-message-component-wrapper">
      <StatusIcon
        data-testid="email-opt-in-post-message-component-icon"
        isError={isError}
      />
      <Text
        displayStyles={dStyles}
        visualStyles={vStyles}
        className="c-email-opt-in-module-text"
      >
        {isError ? (
          <Paragraph
            testId="error-confirmation-message"
            className="c-email-opt-in-error-message"
            content={t('site.email-opt-in.error.description')}
          />
        ) : (
          <Paragraph
            testId="success-confirmation-message"
            className="c-email-opt-in-success-message"
            content={confirmationMessage}
          />
        )}
      </Text>
    </PostMessageWrapper>
  );
};

EmailOptInPostMessage.propTypes = {
  confirmationMessage: PropTypes.string,
  isError: PropTypes.bool,
};
