import React from 'react';
import PropTypes from 'prop-types';
import { CheckInput } from '../sharedComponents';

export function Radio(props) {
  return (
    <CheckInput id={`${props.name}_${props.value}`} type="radio" {...props} />
  );
}

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  rules: PropTypes.shape({}),
};
