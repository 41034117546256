import React from 'react';
import PropTypes from 'prop-types';
import { CheckInput } from '../sharedComponents';

export function Checkbox(props) {
  return <CheckInput id={props.name} type="checkbox" {...props} />;
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  rules: PropTypes.shape({}),
};
