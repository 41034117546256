// Display styles constants
export const DEFAULT = 'DEFAULT';
export const COMPACT = 'COMPACT';

// Campaign statuses
export const PENDING = 'PENDING';
export const SUBSCRIBED = 'SUBSCRIBED';
export const UNCONFIRMED = 'UNCONFIRMED';
export const CONFIRMED = 'CONFIRMED';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
