import styled, { css } from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const StatusIcon = styled.div`
  position: relative;
  display: block;
  width: 54px;
  height: 54px;
  margin: 0 auto 20px;
  border-radius: 50%;
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements.postMessage?.elements?.icon,
    )}
  ${({ theme, isError }) =>
    renderStyledElementStyles(
      theme,
      isError
        ? theme.module.vStyles.elements.postMessage?.elements?.icon?.elements
            ?.error
        : theme.module.vStyles.elements.postMessage?.elements?.icon?.elements
            ?.success,
    )}
  
  &::before {
    content: '';
    position: absolute;
    transform: rotate(-45deg);
    right: 50%;
    top: 50%;
    width: ${({ isError }) => (isError ? '15px' : '16px')};
    height: ${({ isError }) => (isError ? '2px' : '7px')};
    margin-top: ${({ isError }) => (isError ? '-1px' : '-5px')};
    margin-right: ${({ isError }) => (isError ? '-7px' : '-8px')};
    ${({ theme, isError }) =>
      renderStyledElementStyles(
        theme,
        isError
          ? theme.module.vStyles.elements.postMessage?.elements?.icon?.elements
              ?.error?.elements?.before
          : theme.module.vStyles.elements.postMessage?.elements?.icon?.elements
              ?.success?.elements?.before,
      )}
  }
  &::after {
    position: absolute;
    content: '';
    ${({ isError }) =>
      isError &&
      css`
        width: 15px;
        height: 2px;
        top: 50%;
        right: 50%;
        margin-right: -7px;
        margin-top: -1px;
        transform: rotate(45deg);
      `}
    ${({ theme, isError }) =>
      renderStyledElementStyles(
        theme,
        isError
          ? theme.module.vStyles.elements.postMessage?.elements?.icon?.elements
              ?.error?.elements?.after
          : theme.module.vStyles.elements.postMessage?.elements?.icon?.elements
              ?.success?.elements?.after,
      )}
  }
`;
