import React from 'react';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { Input } from '../sharedComponents';

const normalizeValue = (event, rules) => {
  // Allowed characters list according to GEN-153
  const regexToValidate =
    rules.postcode || rules.email || rules.telephone
      ? /[^a-zA-Z0-9\s`~!@#$%^&*()_+=\-[\]\\|}{;'":,./?><]+/gi
      : /[^\x20-\x7E\xA0-\xFF]+/gi;

  event.target.value = event.target.value.replace(regexToValidate, '');
};

export function TextField(props) {
  const {
    type,
    name,
    label,
    helperText,
    placeholder,
    rules: outerRules,
    ...rest
  } = props;

  const { register, control } = useFormContext();
  const {
    field: { onChange, onBlur },
  } = useController({ name, control });

  const trimValue = (event) => {
    if (type !== 'number') {
      const value = event.target.value;
      const trimmedValue = value.trim();

      if (value !== trimmedValue) {
        event.target.value = trimmedValue;
        onChange(event);
      }
    }
  };

  return (
    <Input
      type={type}
      name={name}
      label={label}
      helperText={helperText}
      rules={outerRules}
    >
      {({ rules, onFocus, onBlur: innerOnBlur }) => {
        return type === 'textarea' ? (
          <textarea
            {...rest}
            id={name}
            onFocus={onFocus}
            placeholder={placeholder}
            {...register(name, rules)}
            onChange={(event) => {
              normalizeValue(event, rules);
              onChange(event);
            }}
            onBlur={(event) => {
              trimValue(event);
              onBlur();
              innerOnBlur(event);
            }}
          />
        ) : (
          <input
            {...rest}
            type={type}
            id={name}
            onFocus={onFocus}
            placeholder={placeholder}
            {...register(name, rules)}
            onChange={(event) => {
              normalizeValue(event, rules);
              onChange(event);
            }}
            onBlur={(event) => {
              trimValue(event);
              onBlur();
              innerOnBlur(event);
            }}
          />
        );
      }}
    </Input>
  );
}

TextField.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'textarea',
    'number',
    'email',
    'tel',
    'url',
    'hidden',
  ]).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  helperText: PropTypes.node,
  rules: PropTypes.shape({}),
};
