import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';

export function CopyBillingAnchor(props) {
  const {
    isPartneringOne,
    billingDetails: {
      forename,
      surname,
      telephone,
      email,
      companyName,
      addressLine1,
      addressLine2,
      city,
      postcode,
      state,
      country,
    },
    children,
  } = props;

  const { setValue, trigger } = useFormContext();

  const onCopyBillingDetails = async () => {
    setValue('forename', forename);
    setValue('surname', surname);
    setValue('telephone', telephone);
    setValue('companyName', companyName);
    setValue('addressLine1', addressLine1);
    setValue('addressLine2', addressLine2);
    setValue('city', city);
    setValue('postcode', postcode);

    await setValue('country', country);
    if (state) {
      setValue('state', state);
    }

    if (!isPartneringOne) {
      setValue('email', email);
    }

    await trigger(
      [
        'forename',
        'surname',
        'telephone',
        'companyName',
        'addressLine1',
        'addressLine2',
        'city',
        'postcode',
        state && 'state',
        'country',
        'email',
      ].filter(Boolean),
    );
  };

  return (
    <span
      onClick={onCopyBillingDetails}
      className="fc-secondary"
      style={{
        textDecoration: 'underline',
        cursor: 'pointer',
      }}
    >
      {children}
    </span>
  );
}

CopyBillingAnchor.propTypes = {
  isPartneringOne: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    billingDetails: state.billingDetails.data,
  };
}

export default connect(mapStateToProps)(CopyBillingAnchor);
