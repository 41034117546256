import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const PrivacyPolicyText = styled.p`
  ${({ theme, visualStyles }) =>
    renderStyledElementStyles(theme, visualStyles.elements.privacyPolicyText)}
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements.privacyPolicyText,
    )}
`;
