import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { StyledSelect } from '../../styles';

export function Select(props) {
  const {
    name,
    options,
    rules,
    hasError,
    children,
    placeholder,
    testId,
    ...rest
  } = props;

  const { register } = useFormContext();
  return (
    <StyledSelect
      {...rest}
      id={name}
      hasError={hasError}
      {...register(name, rules)}
      data-testid={testId}
    >
      <option value="">{placeholder}</option>

      {options?.length
        ? options.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))
        : children}
    </StyledSelect>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  hasError: PropTypes.bool,
  rules: PropTypes.shape({}),
};
