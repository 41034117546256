import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { Heading } from '../../../sharedComponents';
import EmailOptInForm from '../../innerComponents/EmailOptInForm';
import { HeadingWrapper, Text } from '../../styles';
import { renderHTMLString } from '../../../../../../helpers';
import { ERROR, SUBSCRIBED } from '../../utils/constants';
import { EmailOptInPostMessage } from '../../innerComponents/EmailOptInPostMessage';

export const EmailOptInContent = (props) => {
  const {
    cookieName,
    headingText,
    descriptionText,
    buttonText,
    sapProductCode,
    confirmationMessage,
    subscriptionStatus,
    setSubscriptionStatus,
  } = props;

  const themeConfig = useContext(ThemeContext);
  const dStyles = themeConfig.module.dStyles;
  const vStyles = themeConfig.module.vStyles;

  const { xs, md, offset } = dStyles.contentGrid;

  const isSubmitted = subscriptionStatus === SUBSCRIBED;
  const isError = subscriptionStatus === ERROR;

  return isSubmitted || isError ? (
    <EmailOptInPostMessage
      confirmationMessage={confirmationMessage}
      subscriptionStatus={subscriptionStatus}
      isError={isError}
      data-testid="email-opt-in-post-message"
    />
  ) : (
    <div className="container" data-testid="email-opt-in-content-wrapper">
      <div className="row">
        <div className={`col-xs-${xs} col-md-${md} col-md-offset-${offset}`}>
          <div className="text-center">
            <HeadingWrapper displayStyles={dStyles} visualStyles={vStyles}>
              <Heading>{renderHTMLString(headingText)}</Heading>
            </HeadingWrapper>
            <Text visualStyles={vStyles} displayStyles={dStyles}>
              {renderHTMLString(descriptionText)}
            </Text>
          </div>
        </div>
      </div>
      <div className="row">
        <div className={`col-xs-${xs}`}>
          <EmailOptInForm
            cookieName={cookieName}
            sapProductCode={sapProductCode}
            buttonText={buttonText}
            setSubscriptionStatus={setSubscriptionStatus}
          />
        </div>
      </div>
    </div>
  );
};

EmailOptInContent.propTypes = {
  cookieName: PropTypes.string,
  headingText: PropTypes.oneOfType([PropTypes.string, () => null]),
  descriptionText: PropTypes.oneOfType([PropTypes.string, () => null]),
  buttonText: PropTypes.string,
  sapProductCode: PropTypes.string,
  confirmationMessage: PropTypes.string,
  subscriptionStatus: PropTypes.string,
  setSubscriptionStatus: PropTypes.func,
};
